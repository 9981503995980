import React from 'react';

const AboutUs = () => {
  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      padding: '20px',
      lineHeight: '1.6',
    },
    header: {
      fontSize: '2em',
      color: '#333',
      marginBottom: '10px',
    },
    section: {
      marginBottom: '20px',
    },
    subHeader: {
      fontSize: '1.5em',
      color: '#555',
      marginBottom: '10px',
    },
    listItem: {
      marginBottom: '5px',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>About Us</div>
      
      <div style={styles.section}>
        <div style={styles.subHeader}>Summary</div>
        <p>
          Experienced Technical Consultant with a proven track record in leading projects and delivering exceptional results. 
          With a background in Full Stack Development, frontend development, and system analysis, I have acquired a diverse 
          skill set that includes React-Native, JavaScript, TypeScript, ReactJs, MERN, and Python. I have successfully 
          completed projects and led development from scratch for startups like Flitpay and Equip9. My future goals include 
          further honing my skills as a Frontend Engineer and FullStack Development.
        </p>
      </div>

      <div style={styles.section}>
        <div style={styles.subHeader}>Work Experience</div>
        <ul>
          <li style={styles.listItem}><strong>SDE III, P99SOFT, Pune</strong> - Dec 2023 - Present</li>
          <li style={styles.listItem}><strong>Senior Consultant, Equip9 Internet Pvt. Ltd., Pune</strong> - Oct 2021 - Oct 2023</li>
          <li style={styles.listItem}><strong>Technical Consultant, Crave Infotech, Pune</strong> - March 2019 - Oct 2021</li>
        </ul>
      </div>

      <div style={styles.section}>
        <div style={styles.subHeader}>Skills</div>
        <ul>
          <li style={styles.listItem}>React Native</li>
          <li style={styles.listItem}>React JS</li>
          <li style={styles.listItem}>JavaScript</li>
          <li style={styles.listItem}>TypeScript</li>
          <li style={styles.listItem}>Python</li>
          <li style={styles.listItem}>AWS</li>
          <li style={styles.listItem}>MERN</li>
        </ul>
      </div>

      <div style={styles.section}>
        <div style={styles.subHeader}>Projects</div>
        <ul>
          <li style={styles.listItem}><strong>Equip9 App:</strong> Aggregated Platform for heavy Equipment Industry.</li>
          <li style={styles.listItem}><strong>MyAdani Gas:</strong> Developed 10 applications from scratch.</li>
          <li style={styles.listItem}><strong>ERMS:</strong> Emergency Response System for Hazard Reporting.</li>
          <li style={styles.listItem}><strong>Flitpay:</strong> Cryptocurrency exchange platform.</li>
        </ul>
      </div>
    </div>
  );
};

export default AboutUs;
