import * as React from "react";
import './App.css';
import background from "./Assets/people.JPG";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import AboutUs from "./Screens/Components/About";

class App extends React.Component {
  render() {
    return (
      <Router>
        <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}>
          <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', backgroundSize: 'cover' }}>
            <header className="App-header">
              <p style={{ color: '#fff', fontSize: 40, fontFamily: 'CormorantSCBold' }}>
                Welcome to
              </p>
              <p style={{ color: '#fff', fontSize: 60, fontFamily: 'CormorantSCBold', marginTop: 0 }}>
                The Layman club
              </p>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                <button onClick={() => {

                }}>Sign Up</button>
                <button>Sign In</button>
              </div>
            </header>
            <ul className="App-header">
              <li>
                <Link to="/about">
                  About Me
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <Routes>
          <Route
            path="/about"
            element={<AboutUs />}
          ></Route>
        </Routes>
      </Router>

    );
  }
}
export default App;
